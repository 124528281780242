import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/app/[locale]/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.144_@babel+core@7.25.2_babel-plugin-react-compiler@0.0.0-experimental-6067_oijyjiutvcgxp2e7xyejxait3q/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.144_@babel+core@7.25.2_babel-plugin-react-compiler@0.0.0-experimental-6067_oijyjiutvcgxp2e7xyejxait3q/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.144_@babel+core@7.25.2_babel-plugin-react-compiler@0.0.0-experimental-6067_oijyjiutvcgxp2e7xyejxait3q/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.144_@babel+core@7.25.2_babel-plugin-react-compiler@0.0.0-experimental-6067_oijyjiutvcgxp2e7xyejxait3q/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Ubuntu\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\"}],\"variableName\":\"ubuntu\"}");
;
import(/* webpackMode: "eager", webpackExports: ["TailwindIndicator"] */ "/vercel/path0/src/components/utils/TailwindIndicator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextTopLoader"] */ "/vercel/path0/src/features/page/NextTopLoader.tsx");
